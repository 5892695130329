@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 0;

  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  width: 100vw;
  overflow: auto;

  @media (--viewportMedium) {
    width: calc(100vw - 48px);
  }
  @media (--viewportLarge) {
    width: unset;
  }
}
:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--colorWhite);
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    border-radius: var(--borderRadiusMedium);
    border: 1px solid var(--colorGrey100);
    overflow: hidden;
  }
}

:global(.fullscreen) {
  background-color: var(--colorWhite) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}
.listingGallary {
  display: flex;
  height: 50vh;
  position: relative;
  cursor: pointer;
  & .listingGallaryItem {
    position: relative;
    overflow: hidden;
    & > img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:first-child {
      flex: 4;
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-evenly;
      align-content: stretch;
      flex: 2;
      padding: 0 4px;
    }
    &:nth-child(3) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: space-evenly;
      align-content: stretch;
      flex: 2;
    }
    & .listingGallarySubItem {
      flex: 1;
      float: none;
      height: 50%;
      width: 100%;
      position: relative;
      overflow: hidden;
      & > img {
        cursor: pointer;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:first-child {
        margin-bottom: 4px;
      }
    }
  }
}
.root {
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 88px;
  max-height: 88px;
  border-radius: var(--borderRadiusMedium);
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */
  display: none;

  composes: buttonSmall from global;

  /* Position and dimensions */
  position: absolute;
  bottom: 20px;
  right: 20px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  @media (--viewportLarge) {
    display: block;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorBlack);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.gallarySlider {
  position: relative;
  & :global(.slick-slider) {
    margin: 0;
  }
  & :global(.slick-slide) {
    padding: 0;
  }
  & :global(.slick-arrow) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;
    &::before {
      display: none;
    }
  }
  & :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }
  & :global(.slick-next > svg) {
    transform: rotate(180deg);
  }
  & :global(.slick-dots) {
    bottom: 10px;
  }
  & :global(.slick-dots > li) {
    margin: 0;
  }
  & :global(.slick-dots > li > button) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--colorText);
    border: none;
    margin: 0 6px 0 0;
  }

  & :global(.slick-dots > li.slick-active > button) {
    background-color: var(--marketplaceColor);
  }
  & :global(.slick-dots > li > button::before) {
    display: none;
  }
}
.gallaryDesktopSec {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 24px;
  min-height: 50vh;
  & .itemWrapper {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 5;
    }
    &:nth-child(2) {
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    &:nth-child(3) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    &:nth-child(4) {
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 3;
      grid-row-end: 5;
    }
    &:nth-child(5) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 3;
      grid-row-end: 5;
    }
  }
}
.gallarySec {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  & :global(.slick-list) {
    height: auto !important;
  }
  & :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    top: 50%;
    /* transform: translateY(-50%); */
    width: 30px;
    height: 30px;
    display: inline-flex;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    background-color: var(--colorText);
    border: solid 1px var(--colorText);
    transition: var(--transitionStyle);
    @media (--viewportSmall) {
      width: 40px;
      height: 40px;
    }
    &:hover {
      background-color: var(--colorGrey50);
      border: solid 1px var(--colorGrey500);
      transition: var(--transitionStyle);
    }
  }
  & :global(.slick-arrow > svg) {
    width: 20px;
    height: 20px;
    @media (--viewportSmall) {
      width: 30px;
      height: 30px;
    }
  }
  & :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }
  & :global(.slick-next > svg) {
    transform: rotate(180deg);
  }
  & :global(.slick-prev::before),
  & :global(.slick-next::before) {
    display: none;
  }
  & :global(.slick-prev) {
    left: 10px;
  }

  & :global(.slick-next) {
    right: 10px;
  }
  & :global(.slick-dots) {
    position: absolute;
    bottom: 20px;
  }
  & :global(.slick-dots > li) {
    margin: 0;
  }
  & :global(.slick-dots > li > button) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--colorGrey100);
    border: none;
    margin: 0 6px 0 0;
  }

  & :global(.slick-dots > li.slick-active > button) {
    background-color: var(--marketplaceColor);
  }
  & :global(.slick-dots > li > button::before) {
    display: none;
  }
}
.viewPhotos {
  position: absolute;
  bottom: 18px;
  right: 10px;
  z-index: 2;
  & > button {
    padding: 8px 10px;
    line-height: 100%;
    min-height: auto;
    border-radius: 5px;
    height: auto;
    display: inline-block;
    min-height: auto;
    height: auto;
    background-color: var(--colorText);
    color: var(--marketplaceColor);
    @media (--viewportMedium) {
      padding: 8px 20px;
    }
    &:hover {
      color: var(--colorText);
    }
  }
}
.modalGallarySlider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & .gallarySec {
    height: 100%;
    @media (max-width: 767px) {
      height: 48% !important;
    }

    & :global(.slick-list) {
      height: 100% !important;
    }
    & :global(.slick-track) {
      height: 100%;
    }
    & :global(.slick-slide) {
      width: 100%;
      border: solid transparent;
      & > div {
        height: 100%;
        display: flex;
      }
    }
    & :global(.slick-prev) {
      left: 20px !important;
    }

    & :global(.slick-next) {
      right: 20px !important;
    }
  }
  & .gallaryDesktopSec {
    display: block;
    max-height: initial;
  }
  & .itemWrapper {
    & > div {
      padding-bottom: 66.66% !important;
    }
  }
}
